import React, { useState, useEffect, useContext } from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { navigate } from "gatsby-link";
import api from "../../api";
import { AuthContext } from "../../context/GlobalContextProvider";
import { Button } from "antd";
import PredioComponent from "../generics/predios";

export default function EmAndamento() {
  const [data, setData] = useState();

  const context = useContext(AuthContext);
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    var response = await api.get(
      "/predios?estado=EmAndamento&_sort=classe:ASC"
    );
    setData(response.data);
  };

  return (
    <Layout aboveFooter titlePage="Obras em Andamento" loading={!data}>
      <SEO
        keywords={[`Golden`, `Sondagens`, `SPT`, `Construção`]}
        title="Em Andamento"
      />
      {context?.user && (
        <Button
          className="mb-10"
          style={{ borderColor: "orange", color: "orange" }}
          danger
          onClick={() => navigate(`/edicao/emAndamento`)}
        >
          Editar
        </Button>
      )}
      <PredioComponent buildings={data} />
    </Layout>
  );
}
